import {InvoiceModel, RaitingSettingModel, InvoiceStaffRatingModel, CustomerModel, UserModel} from '@/models'


export enum ActionName {
    SET_INVOICE = 'SET_INVOICE',
    SET_RAITING_SETTING = 'SET_RAITING_SETTING',
    SET_INVOICE_STAFF_RAITING= 'SET_INVOICE_STAFF_RAITING',
    SET_ICON_RAITNG = 'SET_ICON_RAITNG',
    SET_FEEDBACK_RAITING = 'SET_FEEDBACK_RAITING',
    UN_SET_FEEDBACK_RAITING= 'UN_SET_FEEDBACK_RAITING',
    SET_OTHER_FEEDBACK_RAITING = 'SET_OTHER_FEEDBACK_RAITING',
    SET_TIP_FOR_STAFF = 'SET_TIP_FOR_STAFF',
    SET_CURRENT_STAFF_TIP_TYPING = 'SET_CURRENT_STAFF_TIP_TYPING',
    SET_CUSTOMER_FOR_INVOICE = 'SET_CUSTOMER_FOR_INVOICE',
    SET_USER  = 'SET_USER'
}


export interface ActionType {
    type: ActionName,
    payload: any
}

// set invoice
export const setInvoice = (payload: InvoiceModel): ActionType =>{
    return {
        type: ActionName.SET_INVOICE,
        payload
    }
}


// set raiting setting
export const setRaitingSetting = (payload: RaitingSettingModel): ActionType =>{
    return {
        type: ActionName.SET_RAITING_SETTING,
        payload
    }
}



// set InvoiceStaffRaiting
export const setInvoiceStaffRaiting = (payload: InvoiceStaffRatingModel[]): ActionType => {
    return {
        type: ActionName.SET_INVOICE_STAFF_RAITING,
        payload
    }
}


export const setIconRaiting = (payload: Pick<InvoiceStaffRatingModel, "staffId" | "ratingItemId">) => {
    return {
        type: ActionName.SET_ICON_RAITNG,
        payload
    }
}

export const setFeedbackRaiting = (payload: {staffId?: string,default_feedback_id?: string }) => {
    return {
        type: ActionName.SET_FEEDBACK_RAITING,
        payload
    }
}


export const unSetFeedbackRaiting = (payload: {staffId?: string,default_feedback_id?: string }) => {
    return {
        type: ActionName.UN_SET_FEEDBACK_RAITING,
        payload
    }
}

export const setOtherFeedbackRaiting = (payload: Pick<InvoiceStaffRatingModel, "staffId" | "otherFeedback">) => {
    return {
        type: ActionName.SET_OTHER_FEEDBACK_RAITING,
        payload
    }
}

export const setTipForStaff = (payload: Pick<InvoiceStaffRatingModel, "staffId" | "tip"> & {isKeyBoard?: boolean}) => {
    return {
        type: ActionName.SET_TIP_FOR_STAFF,
        payload
    }
}
 
export const setCurrentStaffTipTyping = (payload: string)=> {
    return {
        type: ActionName.SET_CURRENT_STAFF_TIP_TYPING,
        payload
    }
}

export const setCustomerForInvoice = (payload:CustomerModel ) => {
    return {
        type: ActionName.SET_CUSTOMER_FOR_INVOICE,
        payload
    }
}

export const setUser = (payload?: UserModel) => {
    return {
        type: ActionName.SET_USER,
        payload
    }
}
