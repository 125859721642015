import React, {
  createContext,
  ReactElement,
  useContext,
  useReducer,
  Dispatch,
} from "react";
import { initialState, reducer, IInitialState, reducerType } from "./reducer";
import { ActionType } from "./actions";

export type ContextValueType = [IInitialState, Dispatch<ActionType>];

const Context = createContext<ContextValueType>([initialState, () => void {}]);

export function StoreProvider(props: { children: ReactElement | JSX.Element }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Context.Provider value={[state, dispatch]}>
      {props.children}
    </Context.Provider>
  );
}

export const useStore = () => useContext(Context);
